export const isProduction = true;
export const googlemapsapikey = 'AIzaSyDT8u2W9mcuJ-uESWb9ZAcd6sd6hkmAMVs';
export const environment = Object.freeze({
  production: true,
  brand: 'fr',
  reclameAquiUrl: 'https://raresponde.reclameaqui.com.br/faleconosco/index/?client=13077',
  bizutUrl: 'https://www.bizut.me/vagas/publicas/5393',
  domain: 'formulaacademia.com.br',

  // tslint:disable:max-line-length
  seo: {
    DEFAULT_FULL_TITLE: 'Fórmula Academia',
    DEFAULT_TITLE: 'Fórmula',
    DEFAULT_META_DESCRIPTION: 'Nas academias Fórmula você conta com os melhores profissionais e programas exclusivos de musculação e aulas coletivas para cuidar de sua saúde física e mental.',
  },

  sentry: {
    dsn: 'https://f2f0698bb32a4f98b9004d7ad410f0fe@sentry.dev.bodytech.company/6',
    enabled: true,
    environment: 'prod',
    release: 'frportal-__SENTRY_RELEASE__',
    attachStacktrace: true,
  },

  services: {
    baseUrl: 'https://services.formulaacademia.bodytech.company',
    evoBaseUrl: 'https://evo-integracao.w12app.com.br',
  },

  recaptchaKey: '6LewKJcUAAAAAPciiJUv4P8SN1iRWntrKOfp1htq',

  wordpress: {
    baseUrl: 'https://blog.bodytech.com.br',
  },

  apollo: {
    baseUrl: 'https://www.formulaacademia.com.br/api',
    microservicesBaseUrl: 'https://services.authentication.prd.bodytech.company/graphql'
  },

  docmod: {
    microservicesBaseUrl: 'https://services.docmod.prd.bodytech.company/graphql',
    key: 'd98ff7f5-e812-40e5-bb1f-e4b7c1144e2a',
    system: 'PORTAL',
    xApiKey: 'da2-pe5teasgxrcwne2bdv2ujhsjjm',
  },

  googleAnalytics: {
    trackingID: 'UA-46889722-3',
    tagManagerId: 'G-HQKHWW78WE',
  },

  facebook: {
    pixelId: '533896610092976',
  },

  s3buckets: {
    images: 'https://images.formulaacademia.bodytech.company',
    medias: 'https://medias.bodytech.company',
  },

  evo: {
    scheduleBaseUrl: 'https://admin.formulaacademia.com.br/formula',
  },
});
